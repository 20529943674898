import Zoid from 'zoid';
import {NavigationBar} from './interfaces/navigationBar';
import {PortalMessage} from './interfaces/portalMessage';
import {zoidProps} from './zoidProps';

declare global {
    interface Window {
        portalExtension: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            xprops: any;
        };
    }
}

export interface PortalParameters {
    deviceId: string;
}

const fetchZoidJsFile = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.addEventListener('load', () => {
            resolve(null);
        });
        script.addEventListener('error', reject);
        document.body.appendChild(script);
    });
};

export type TokenChangeListener = (token: string) => void;
type OnTokenChange = (callback: (token: string) => void) => void;

const tokenChangeListeners: Set<TokenChangeListener> = new Set();

export class PortalHelper {
    static initializeZoid(extensionTag: string, extensionUrl = ''): void {
        window.portalExtension = Zoid.create({tag: extensionTag, url: extensionUrl, props: zoidProps});
        this.subscribeToGroupIdChanges();
        this.subscribeToTokenChange();
    }

    static async fetchZoidFromPortal(portalUrl: string, tag: string, url: string): Promise<boolean> {
        return fetchZoidJsFile(`${portalUrl}/zoid/zoid.min.js`)
            .then(() => {
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                window.portalExtension = (window as any).zoid?.create({
                    tag,
                    url,
                    props: zoidProps
                });
                this.subscribeToGroupIdChanges();
                this.subscribeToTokenChange();
                return true;
            })
            .catch((err) => {
                console.log('Fetching of zoid version failed: ', err);
                return false;
            });
    }

    static subscribeToLanguageChanges(callback: (language: string) => void): void {
        const onLanguageChange = window.portalExtension?.xprops?.onLanguageChange;
        if (onLanguageChange) {
            onLanguageChange(callback);
        }
    }

    static async getGroupId(): Promise<string> {
        return window.portalExtension?.xprops?.getCurrentGroupId();
    }

    private static subscribeToGroupIdChanges(): void {
        const onGroupIdChange = window.portalExtension?.xprops?.onGroupIdChange;
        if (onGroupIdChange) {
            onGroupIdChange(() => {
                window.location.href = '/';
            });
        }
    }

    static subscribeToUnitChanges(callback: (unit: string) => void): void {
        const onUnitChange = window.portalExtension?.xprops?.onUnitChange;
        if (onUnitChange) {
            onUnitChange(callback);
        }
    }

    static subscribeToPathChanges(callback: (path: string) => void): void {
        const onPathChange = window.portalExtension?.xprops?.onPathChange;
        if (onPathChange) {
            onPathChange(callback);
        }
    }

    static async getAuthToken(): Promise<string> {
        return window.portalExtension?.xprops?.getAuthToken();
    }

    static updateNavigationBar(navigationBar: NavigationBar): void {
        const xprops = window.portalExtension?.xprops;
        if (xprops) {
            xprops.setNavigationBar(navigationBar);
        }
    }

    static getCurrentLanguage(): string | undefined {
        const xprops = window.portalExtension?.xprops;
        if (xprops) {
            return xprops.getCurrentLanguage;
        }

        return undefined;
    }

    static getCurrentUnit(): string | undefined {
        return window.portalExtension?.xprops?.getCurrentUnit;
    }

    static getCurrentPath(): string | undefined {
        return window.portalExtension?.xprops?.getCurrentPath;
    }

    static displayProgressBar(visible: boolean): void {
        window.portalExtension?.xprops?.showProgressBar(visible);
    }

    static sendMessage(message: PortalMessage): void {
        window.portalExtension?.xprops?.sendMessage(message);
    }
    static sendSuccessMessage(message: string): void {
        this.sendMessage({active: true, type: 'success', message});
    }
    static sendErrorMessage(message: string): void {
        this.sendMessage({active: true, type: 'error', message});
    }

    static getPortalParameters(): PortalParameters | undefined {
        return window.portalExtension?.xprops?.portalParameters;
    }

    static addTokenChangeListener(listener: TokenChangeListener): () => void {
        tokenChangeListeners.add(listener);
        return () => {
            tokenChangeListeners.delete(listener);
        };
    }

    static get available(): boolean {
        return window.portalExtension?.xprops !== undefined;
    }

    private static subscribeToTokenChange(): void {
        const onTokenChange: OnTokenChange = window.portalExtension?.xprops?.onTokenChange;
        if (onTokenChange) {
            onTokenChange((token) => {
                tokenChangeListeners.forEach((listener) => listener(token));
            });
        }
    }
}
